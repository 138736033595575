export function cssVarSupport() {
  function get_browser() {
    var ua = navigator.userAgent,
      tem,
      M =
        ua.match(
          /(opera|chrome|safari|firefox|msie|trident(?=\/))\/?\s*(\d+)/i
        ) || [];
    if (/trident/i.test(M[1])) {
      tem = /\brv[ :]+(\d+)/g.exec(ua) || [];
      return { name: "IE", version: tem[1] || "" };
    }
    if (M[1] === "Chrome") {
      tem = ua.match(/\bOPR|Edge\/(\d+)/);
      if (tem != null) {
        return { name: "Opera", version: tem[1] };
      }
    }
    M = M[2] ? [M[1], M[2]] : [navigator.appName, navigator.appVersion, "-?"];
    if ((tem = ua.match(/version\/(\d+)/i)) != null) {
      M.splice(1, 1, tem[1]);
    }
    return {
      name: M[0],
      version: M[1],
    };
  }

  document.documentElement.className = "js";
  var supportsCssVars = function () {
    var e,
      t = document.createElement("style");
    return (
      (t.innerHTML = "root: { --tmp-var: bold; }"),
      document.head.appendChild(t),
      (e = !!(
        window.CSS &&
        window.CSS.supports &&
        window.CSS.supports("font-weight", "var(--tmp-var)")
      )),
      t.parentNode.removeChild(t),
      e
    );
  };

  var browser = get_browser();
  supportsCssVars() ||
    alert(
      "Ciao! " +
        browser.name +
        " v." +
        browser.version +
        " potrebbe impedire al sito di visualizzarsi correttamente. Per favore usa un browser aggiornato."
    );
}
